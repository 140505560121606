import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';

export const rootRouterConfig: Routes = [
  { 
    path: '', 
    redirectTo: 'dashboard', 
    pathMatch: 'full' 
  },
  {
    path: '', 
    component: AuthLayoutComponent,
    children: [
      { 
        path: 'sessions', 
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session'} 
      }
    ]
  },
  {
    path: '', 
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule), 
        data: { title: 'Dashboard', breadcrumb: 'Dashboard'}
      },
      {
        path: 'battery-data-analysis-report',
        loadChildren: () => import('./views/battery-data-analysis-report/battery-data-analysis-report.module').then(m => m.BatteryDataAnalysisReportModule),
        data: { title: 'Battery-data-Analysis-report', breadcrumb: 'Battery data Analysis report'}

      },
      {
        path: 'user-management',
        loadChildren: () => import('./views/user-management/user-management.module').then(m => m.UserManagementModule), 
        data: { title: 'User Management', breadcrumb: 'User Management'}
      },
      {
        path: 'battery-excel-download',
        loadChildren: () => import('./views/battery-excel-download/battery-excel-download.module').then(m => m.BatteryExcelDownloadModule), 
        data: { title: 'Battery Excel Download', breadcrumb: 'Battery Excel Download'}
      },
      {
        path: 'bms-management',
        loadChildren: () => import('./views/bms-management/bms-management.module').then(m => m.BmsManagementModule), 
        data: { title: 'BMS Management', breadcrumb: 'BMS Management'}
      },
      {
        path: 'services-plan',
        loadChildren: () => import('./views/services-plan/services-plan.module').then(m => m.ServicesPlanModule), 
        data: { title: 'Services Plan', breadcrumb: 'Services Plan'}
      },
      {
        path: 'customerDashboard',
        loadChildren: () => import('./views/customer-dashboard/customerDashboard.module').then(m => m.CustomerDashboardModule), 
        data: { title: 'Dashboard', breadcrumb: 'Dashboard'}
      },
      {
        path: 'others', 
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule), 
        data: { title: 'Others', breadcrumb: 'OTHERS'}
      },
      {
        path: 'search', 
        loadChildren: () => import('./views/search-view/search-view.module').then(m => m.SearchViewModule)
      }
    ]
  },
  { 
    path: '**', 
    redirectTo: 'sessions/404'
  }
];

