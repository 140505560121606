import { Injectable } from "@angular/core";
import { LocalStoreService } from "../local-store.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError, delay } from "rxjs/operators";
import { User } from "../../models/user.model";
import { of, BehaviorSubject, throwError } from "rxjs";

import { environment } from "../../../../environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationService } from "../navigation.service";
import { Response } from "../../models/response.model";
import { MenuItem } from "../../models/menuItem.model";
import { TranslateService } from '@ngx-translate/core';
import { AppLoaderService } from "../app-loader/app-loader.service";


@Injectable({
    providedIn: "root",
})
export class JwtAuthService {
    token;
    refreshToken;
    isAuthenticated: Boolean;
    user: User;
    adminMenus: MenuItem[];
    user$ = (new BehaviorSubject<User>(this.user));
    signingIn: Boolean;
    return: string;
    JWT_TOKEN = "JWT_TOKEN";
    APP_USER = "POWERSWAP_USER";
    APP_USER_PERMISSIONS = 'USER_ACCESS';
    APP_USER_MENU = 'USER_MENU';
    REFRESH_TOKEN = 'REFRESH_TOKEN';
    accessPermissions: string[] = [];
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    'menus': [
        {
            'name': 'DASHBOARD',
            'type': 'link',
            'tooltip': 'Dashboard',
            'icon': 'dashboard',
            'state': 'dashboard',
            'disabled': false,
        },
        {
            'name': 'User Management',
            'type': 'dropDown',
            'tooltip': 'User Management',
            'icon': 'people',
            'state': 'user-management',
            'disabled': false,
            'sub': [
                { 'name': 'Add User', 'disabled': false, 'state': 'add-user' },
            ]
        },
        {
            'name': 'Battery Excel Download',
            'type': 'dropDown',
            'tooltip': 'Battery Excel Download',
            'icon': 'assignment',
            'state': 'battery-excel-download',
            'disabled': false,
            'sub': [
                { 'name': 'Download File', 'disabled': false, 'state': 'download-file' },
            ]
        },
        {
            'name': 'BMS Management',
            'type': 'dropDown',
            'tooltip': 'BMS Management',
            'icon': 'battery_charging_full',
            'state': 'bms-management',
            'disabled': false,
            'sub': [
                { 'name': 'Add battery', 'disabled': false, 'state': 'add-battery' },
                { 'name': 'Add Charging Stations', 'disabled': false, 'state': 'add-charging-stations' },
                { 'name': 'Add Station Operator', 'disabled': false, 'state': 'add-station-operator' },
                { 'name': 'Bms Sota File Upload', 'disabled': false, 'state': 'bms-sota-file-upload' },
                { 'name': 'Tele Fota File Upload', 'disabled': false, 'state': 'tele-fota-file-upload' },
                { 'name': 'Add Battery Charging Station', 'disabled': false, 'state': 'add-battery-charging-station' },
                { 'name': 'Add Admin', 'disabled': false, 'state': 'add-admin' },
                { 'name': 'Device Configuration', 'disabled': false, 'state': 'device-configuration' }
            ]
        },
        {
            'name': 'Services Plan',
            'type': 'link',
            'tooltip': 'Services Plan',
            'icon': 'settings',
            'state': 'services-plan',
            'disabled': false,
        },
        {
            'name': 'Dashboard',
            'type': 'link',
            'tooltip': 'Dashboard',
            'icon': 'dashboard',
            'state': 'customerDashboard',
            'disabled': false,
        },
        {
            'name': 'Battery Data Analysis Report',
            'type': 'link',
            'tooltip': 'Battery Data Analysis Report',
            'icon': 'description',
            'state': 'battery-data-analysis-report',
            'disabled': false,
        }
    ]

    constructor(
        private ls: LocalStoreService,
        private http: HttpClient,
        private router: Router,
        private snack: MatSnackBar,
        private navService: NavigationService,
        private route: ActivatedRoute,
        private loader: AppLoaderService,
        public translateService: TranslateService,
    ) {
        // this.route.queryParams
        //   .subscribe(params => this.return = params['return'] || '/');
    }

    public signin(email, password, roleId) {

        const postLoginUrl = environment.baseUrl + 'adminLogin/web/api/login';
        this.signingIn = true;
        return this.http.post(postLoginUrl, { email, password, roleId: 1 })
            .pipe(
                map((res: Response) => {
                    if (res.status === 'success') {
                        //  localStorage.setItem('languageCode', res.responseData.languageCode);
                        localStorage.setItem('userId', res.responseData.id);
                        this.loader.close();

                        if (res.responseData.role === "admin") {
                            this.router.navigateByUrl("dashboard");
                        } else {
                            this.router.navigateByUrl("customerDashboard");
                        }

                        this.setUserAndToken(res.responseData.accessToken, res.responseData, !!res);
                        this.user = res.responseData;
                        this.user.id = res.responseData.id;
                        this.adminMenus = this.user.menuItems;
                        this.setMenus(this.adminMenus);
                        // /**ToDo uncomment */
                        this.setAccessPermissions(this.adminMenus);

                        this.navService.publishNavigationMenu(this.adminMenus);
                        this.snack.open(this.translateService.instant(res.message), null,
                            { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center' });
                    } else {
                        this.snack.open(this.translateService.instant(res.message), null,
                            { duration: 6000, verticalPosition: 'top', horizontalPosition: 'center' });
                        this.router.navigateByUrl("sessions/signin");
                        this.loader.close();
                    }
                    return res;
                }),
                catchError((error) => {
                    return throwError(error);
                })
            );

    }

    /*
      checkTokenIsValid is called inside constructor of
      shared/components/layouts/admin-layout/admin-layout.component.ts
    */
    public checkTokenIsValid() {

        this.user = this.getUser();
        return of(this.user)
            .pipe(
                map((profile: User) => {
                    this.setUserAndToken(this.getJwtToken(), this.user, true);
                    this.signingIn = false;
                    return profile;
                }),
                catchError((error) => {
                    return of(error);
                })
            );
    }


    public signout() {
        // this.logout().subscribe(res => {
        //   // console.log(res);
        //  this.clearStorage();
        // });
        // this.setUserAndToken(null, null, false);
        this.router.navigateByUrl("sessions/signin");
    }

    public clearStorage() {
        this.setUserAndToken(null, null, null);
        this.setUserAccessPermissions(null);
        sessionStorage.clear();
        // localStorage.clear();
        this.loader.close();
        // console.log("in clear storage before sign in")
        this.router.navigateByUrl("sessions/signin");
        this.loader.close();
        // console.log("in clear storage after ")

    }


    isLoggedIn(): Boolean {
        return !!this.getJwtToken();
    }

    getJwtToken() {
        return this.ls.getItem(this.JWT_TOKEN);
    }
    getUser() {
        if (this.user && this.user.id) {
            return this.user;
        } else {
            return this.ls.getItem(this.APP_USER);
        }
    }

    setUser(user: User) {
        if (user && user.id) {
            this.ls.setItem(this.APP_USER, user);
        }
    }

    setMenus(menus) {
        if (menus) {
            this.ls.setItem(this.APP_USER_MENU, menus);
        }
    }

    // setUserAndToken(token: String, user: User, isAuthenticated: Boolean) {
    //   this.isAuthenticated = isAuthenticated;
    //   this.token = token;
    //   this.user = user;
    //   this.user$.next(user);
    //   this.ls.setItem(this.JWT_TOKEN, token);
    //   this.ls.setItem(this.APP_USER, user);
    // }

    setUserAndToken(token, user: User, isAuthenticated: Boolean) {
        // let tempUser : User = {};
        //  tempUser.role = {};
        if (user && user.id) {

            this.ls.setItem(this.APP_USER, user);
        } else {
            this.ls.setItem(this.APP_USER, null);
        }
        this.isAuthenticated = isAuthenticated;
        //  console.log("tokens jwt auth: " + token)
        this.token = token;
        //  this.refreshToken =refreshToken;
        this.user = user;
        this.user$.next(user);
        this.ls.setItem(this.JWT_TOKEN, this.token);
        this.ls.setItem(this.REFRESH_TOKEN, this.refreshToken)
    }


    setUserAccessPermissions(permissions: string[]) {
        this.ls.setItem(this.APP_USER_PERMISSIONS, permissions);
    }

    getUserAccessPermissions() {
        return this.ls.getItem(this.APP_USER_PERMISSIONS);
    }

    private setAccessPermissions(menuItems)
    //: Observable<any> 
    {
        for (let item of menuItems) {

            if (item.type != 'dropDown') {
                if (item.actions) {
                    this.setActions(item.actions);
                }
            } else {
                for (let itemLvL2 of item.sub) {
                    // if(!itemLvL2.disabled && itemLvL2.actions){
                    if (itemLvL2.actions) {
                        this.setActions(itemLvL2.actions);
                    }
                }
                //}
            }
        }
        this.ls.setItem(this.APP_USER_PERMISSIONS, this.accessPermissions);
        // return of(this.accessPermissions);
    }

    private setActions(actions) {
        for (let action of actions) {
            if (action.hasPermission) {
                this.accessPermissions.push(action.id);
            }
        }
    }
}
